<template>
  <div class="flex flex-col lg:flex-row">
    <div class="lg:flex grid grid-cols-2 gap-4 lg:gap-0">
      <file-input
        ref="fileInputRef"
        v-model="file"
        class="w-32 h-32 mr-2"
        :class="{
          hidden: hideInput,
        }"
        :accepted-types="`${AcceptedFileTypes.IMAGE}, ${AcceptedFileTypes.VIDEO}`"
        :title="$t('post-modal.file-input-title')"
        :rules="fileRules"
        :image-size-limit="SOCIAL_POST_IMAGE_SIZE_LIMIT"
        :video-size-limit="SOCIAL_POST_VIDEO_SIZE_LIMIT"
        :disabled="!props.isEditable || props.socialData.fromContent"
        :validate="validateMultipleMediaType"
        notify-errors
        @upload-complete="addMedia"
        @delete="media = []"
        @upload-started="startUpload"
      />
      <template v-if="allowMultipleMedia">
        <div
          v-for="(mediaItem, index) in media"
          :key="mediaItem.id"
          :class="{
            hidden: hideMediaTile(index),
          }"
          class="w-full aspect-square lg:w-32 lg:h-32 rounded-lg relative mb-4 overflow-hidden mr-2"
        >
          <div
            v-if="showOverflowMediaOverlay(index)"
            class="absolute w-full h-full bg-[#000000]/50 flex items-center justify-center text-3xl"
          >
            <CircularSpinner v-if="isUploading" />
            <span v-else class="text-[white]"
              >+{{ media.length - VISIBLE_MULTIPLE_MEDIA_LIMIT }}</span
            >
          </div>
          <img
            v-if="isFileImage(mediaItem.file.url)"
            :src="mediaItem.file.url"
            class="w-full h-full flex items-center justify-center object-cover"
            alt="logo"
          />
          <video
            v-else
            controls
            :src="mediaItem.file.url"
            class="h-full w-full rounded-lg bg-black"
          />
        </div>
        <div
          v-if="showLoader"
          class="w-full aspect-square lg:w-32 lg:h-32 border border-dashed rounded-lg mb-4 flex items-center justify-center mr-2"
        >
          <CircularSpinner />
        </div>
      </template>
    </div>
    <div
      v-if="canAddMultipleMedia"
      class="flex flex-col gap-3 mb-4 w-full mt-5 lg:mt-0"
      :class="[media.length > 1 ? 'self-end' : 'self-center']"
    >
      <btn
        v-if="showAddButton"
        :variant="BtnVariant.FILLED"
        :color="BtnColor.PRIMARY"
        class="lg:w-25"
        :disabled="isUploading"
        @click="selectFile"
      >
        {{ $t('common.add') }}
      </btn>

      <SocialPostFilesModal
        v-if="media.length > 1 || (media.length > 1 && isEditable)"
        v-model="media"
        :no-caption="socialData.type !== ContentSocialType.FACEBOOK"
        :allow-videos="socialData.type === ContentSocialType.INSTAGRAM"
        :disabled="isUploading"
        :is-file-editable="!props.socialData.fromContent"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { FileInput } from '~/components/file-input';
import { Nullable } from '@/types/common';
import { ContentSocialFileDto, ContentSocialType } from '~/api/contents';
import {
  MULTIPLE_MEDIA_LIMIT,
  VISIBLE_MULTIPLE_MEDIA_LIMIT,
} from '~/pages/my-posts/_components/post-modal-content/_components/media-manager/media-manager.constants';
import { isFileImage, isFileVideo } from '~/utils/file';
import { Btn, BtnColor, BtnVariant } from '~/components/btn';
import { AcceptedFileTypes } from '~/constants/files';
import {
  SOCIAL_POST_IMAGE_SIZE_LIMIT,
  SOCIAL_POST_VIDEO_SIZE_LIMIT,
} from '~/pages/my-posts/my-posts.constants';
import { isRequired } from '~/utils/validators';
import { CircularSpinner } from '~/components/loaders';
import { SocialPostFilesModal } from '~/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import { apiCreateContentFile } from '~/api/files';
import { PostModalContentForm } from '~/pages/my-posts/_components/post-modal-content/post-modal-content.types';

type Props = {
  socialData: PostModalContentForm;
  isEditable?: boolean;
};

const { t } = useI18n();
const props = defineProps<Props>();
const emit = defineEmits<{
  'multiple-media': [value: boolean];
}>();

const media = defineModel<ContentSocialFileDto[]>({ required: true });
const file = ref<Nullable<string>>(null);
const isUploading = ref(false);
const fileInputRef = ref(null);

watch(
  media,
  (value) => {
    const isMultipleMedia = media.value.length > 1;
    emit('multiple-media', isMultipleMedia);
    file.value = value[0]?.file.url ?? null;
  },
  { immediate: true },
);

const validateMultipleMediaType = (file: File) => {
  const videoFileInCarousel =
    media.value.some(({ file }) => isFileVideo(file.url)) ||
    !file.type.includes('image');

  const isValid = !(
    props.socialData.type === ContentSocialType.FACEBOOK &&
    videoFileInCarousel &&
    media.value.length > 0
  );

  return {
    isValid,
    errorMessage: t('media-manager.video-error'),
  };
};

const allowMultipleMedia = computed(
  () => props.socialData.type !== ContentSocialType.LINKEDIN,
);

const hideInput = computed(
  () =>
    allowMultipleMedia.value &&
    (media.value.length > 1 || (media.value.length > 0 && isUploading.value)),
);

const addMedia = async (socialFileUrl: string, editExisting: boolean) => {
  try {
    const file = await apiCreateContentFile(socialFileUrl);

    if (allowMultipleMedia.value) {
      media.value = editExisting
        ? [{ id: file.id, caption: null, file }, ...media.value.slice(1)]
        : [...media.value, { id: file.id, caption: null, file }];
    } else {
      media.value = [{ id: file.id, caption: null, file }];
    }
  } catch {
    notify({
      group: 'error',
      title: t('media-manager.upload-error'),
      duration: 5000,
    });
  } finally {
    isUploading.value = false;
  }
};

const selectFile = () => {
  fileInputRef.value?.browseFiles();
};

const showOverflowMediaOverlay = (index: number) => {
  const isOverflowMedia =
    index + 1 === VISIBLE_MULTIPLE_MEDIA_LIMIT &&
    media.value.length > VISIBLE_MULTIPLE_MEDIA_LIMIT;

  const isLastVisibleMediaUploading =
    media.value.length === VISIBLE_MULTIPLE_MEDIA_LIMIT &&
    isUploading.value &&
    index === VISIBLE_MULTIPLE_MEDIA_LIMIT - 1;

  return isOverflowMedia || isLastVisibleMediaUploading;
};

const hideMediaTile = (index: number) =>
  index + 1 > VISIBLE_MULTIPLE_MEDIA_LIMIT ||
  (media.value.length <= 1 && !isUploading.value);

const canAddMultipleMedia = computed(
  () => media.value.length > 0 && allowMultipleMedia.value && props.isEditable,
);

const showLoader = computed(
  () =>
    isUploading.value &&
    media.value.length > 0 &&
    media.value.length < VISIBLE_MULTIPLE_MEDIA_LIMIT,
);

const showAddButton = computed(
  () =>
    media.value.length < MULTIPLE_MEDIA_LIMIT &&
    !(
      props.socialData.type === ContentSocialType.FACEBOOK &&
      isFileVideo(media.value[0].file.url)
    ) &&
    !props.socialData.fromContent,
);

const fileRules = computed(() => {
  if (props.socialData.type === ContentSocialType.INSTAGRAM) {
    return [isRequired];
  }

  return [];
});

const startUpload = (editExisting: boolean) => {
  if (!editExisting) isUploading.value = true;
};
</script>
